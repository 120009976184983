import React from "react"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import Fade from "react-reveal/Fade"
import Markdown from "react-markdown"
import CircularNavButton from "../../components/circularNavButton"

import hearthWrapper from "../../assets/left-half-hearth.svg"

const IMIDOpinionBanner = ({ cms }) => {
  return (
    <Box
      className="imid-opinion-banner"
      component="section"
      display="flex"
      alignItems="center"
    >
      <img
        className="imid-opinion-banner__hearth-wrapper"
        src={hearthWrapper}
        alt="Ipad with carebits app view"
      ></img>
      <Container>
        <Grid container>
          <Grid item className="imid-opinion-banner__left" xs={12} lg={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              height="100%"
              position="relative"
            >
              <Fade fraction={0.5}>
                <img
                  src={cms.imid.media.localFile.publicURL}
                  alt="IMID logo"
                ></img>
              </Fade>
              <Markdown allowDangerousHtml>{cms.imid.title}</Markdown>
              <CircularNavButton
                text={cms.imid.link_bar.box_title}
                className="primary"
                link={cms.imid.link_bar.box_link}
              ></CircularNavButton>
            </Box>
          </Grid>
          <Grid item className="imid-opinion-banner__right" xs={12} lg={7}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="relative"
            >
              <Markdown allowDangerousHtml>{cms.councils.title}</Markdown>
              <Markdown allowDangerousHtml>{cms.councils.content}</Markdown>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default IMIDOpinionBanner
