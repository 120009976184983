import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import BannerNav from "../banner-nav"

import Markdown from "react-markdown"

const AreYouDoctorBanner = props => {
  const { cms } = props
  return (
    <>
      <Box
        className="are-you-doctor-banner"
        component="section"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Container>
          <div className="are-you-doctor-banner__right">
            <Markdown allowDangerousHtml>{cms.title}</Markdown>
            <Markdown allowDangerousHtml>{cms.content}</Markdown>
            <BannerNav
              boxText={cms.link_bar.box_title}
              boxLink={cms.link_bar.box_link}
              boxId="orderLinkButton"
              circularText={cms.link_bar.circular_title}
              circularLink={cms.link_bar.circular_link}
            ></BannerNav>
          </div>
        </Container>
      </Box>
    </>
  )
}

export default AreYouDoctorBanner
