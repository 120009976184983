import React, { useState } from "react"
import PartnerCarousel from "../../components/partnerCarousel"
import CarouselSterring from "../../components/carouselSterring"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PartnersBanner = props => {
  const { cms } = props
  const [swiper, setSwiper] = useState(null)

  const onSwiperCreated = swiper => {
    setSwiper(swiper)
  }

  const onSwipeNext = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  const onSwipePrev = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  return (
    <>
      <Box
        className="partners-banner"
        component="section"
        display="flex"
        alignItems="center"
      >
        <Container maxWidth="false">
          <Grid container>
            <Grid item className="partners-banner__left" xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <h1>
                  <FormattedMessage
                    id="partnersBanner_Title"
                    defaultMessage="Partnerzy"
                  />
                </h1>
                <p>
                  <FormattedMessage
                    id="partnersBanner_Subtitle"
                    defaultMessage="Firmy partnerskie i współpracujace z Carebits"
                  />
                </p>
                <CarouselSterring
                  swipeNext={onSwipeNext}
                  swipePrev={onSwipePrev}
                ></CarouselSterring>
              </Box>
            </Grid>
            <Grid item className="partners-banner__right" xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <PartnerCarousel
                  data={cms.edges}
                  swipperCreated={onSwiperCreated}
                ></PartnerCarousel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default PartnersBanner
