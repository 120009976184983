import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeBanner from "../sections/home/home-banner"
import WhatYouGetBanner from "../sections/home/what-you-get-banner"
import MainMarketingBanner from "../sections/main-marketing-banner"
import AreYouDoctorBanner from "../sections/home/are-you-doctor-banner"

import { useIntl } from "gatsby-plugin-react-intl"
import PartnersBanner from "../sections/home/partners-banner"
import MomTestimonialsBanner from "../sections/home/moms-testimonials-banner"
import IMIDOpinionBanner from "../sections/home/imid-opinion-banner"
import WhyCarebitsBanner from "../sections/home/why-carebits-banner"

const IndexPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        title={data.strapiHome.seo.title}
        description={data.strapiHome.seo.description}
        keywords={data.strapiHome.seo.keywords}
      />
      <HomeBanner cms={data.strapiHome.homeBanner}></HomeBanner>
      <IMIDOpinionBanner
        cms={{
          imid: data.strapiHome.imidOpinionBanner,
          councils: data.strapiHome.consultantsOpinionBanner,
        }}
      ></IMIDOpinionBanner>
      <WhyCarebitsBanner
        cms={data.strapiHome.whyCarebitsBanner}
      ></WhyCarebitsBanner>
      <WhatYouGetBanner
        cms={data.strapiHome.whatYouGetBanner}
      ></WhatYouGetBanner>
      <MomTestimonialsBanner
        cms={data.allStrapiTestimonial}
      ></MomTestimonialsBanner>
      <AreYouDoctorBanner
        cms={data.strapiHome.areYouDoctorBanner}
      ></AreYouDoctorBanner>
      <PartnersBanner cms={data.allStrapiPartner}></PartnersBanner>
      <MainMarketingBanner
        cms={data.strapiMain.marketingBanner}
      ></MainMarketingBanner>
    </Layout>
  )
}

export const query = graphql`
  query Home($language: String) {
    strapiHome(locale: { eq: $language }) {
      id
      seo {
        title
        description
        keywords {
          tag
        }
      }
      homeBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      imidOpinionBanner {
        title
        media {
          localFile {
            publicURL
          }
        }
        link_bar {
          box_title
          box_link
        }
      }
      consultantsOpinionBanner {
        title
        content
      }
      whyCarebitsBanner {
        content
      }
      whyChooseCarebitsBanner {
        title
        accordion {
          title
          content
        }
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      whatYouGetBanner {
        title
        list {
          content
        }
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
      areYouDoctorBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
    strapiMain(locale: { eq: $language }) {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
    allStrapiPartner(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          position
          name
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allStrapiTestimonial(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          position
          name
          short_opinion
          opinion
          active
          avatar {
            localFile {
              publicURL
            }
          }
          full_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default IndexPage
