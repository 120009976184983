import React from "react"

const SinglePartner = props => {
  const { partner } = props
  return (
    <>
      <div className="single-partner">
        <img src={partner.logo.localFile.publicURL} alt="Logo of partner"></img>
      </div>
    </>
  )
}

export default SinglePartner
