import React from "react"
import PropTypes from "prop-types"
import SinglePartner from "./singlePartner"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.scss"

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core"

// install Swiper modules
SwiperCore.use([Autoplay])

const PartnerCarousel = ({ data, swipperCreated }) => {
  return (
    <>
      <Swiper
        className="partner-carousel"
        onSwiper={swiper => {
          swipperCreated(swiper)
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          900: {
            slidesPerView: 2.25,
            spaceBetween: 50,
          },
          1250: {
            slidesPerView: 4,
            spaceBetween: 100,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {data.map((element, index) => {
          return (
            <SwiperSlide key={index} className="partner-carousel-slide">
              <SinglePartner partner={element.node}></SinglePartner>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

PartnerCarousel.propTypes = {
  data: PropTypes.array,
  swipperCreated: PropTypes.func,
}

PartnerCarousel.defaultProps = {
  data: [],
}

export default PartnerCarousel
