import React from "react"
import PropTypes from "prop-types"
import SingleTestimonial from "./singleTestimonial"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core"

// Import Swiper styles
import "swiper/swiper.scss"

// install Swiper modules
SwiperCore.use([Autoplay])

const TestimonialCarousel = ({ data, swipperCreated }) => {
  return (
    <>
      <Swiper
        className="testimonial-carousel"
        onSwiper={swiper => swipperCreated(swiper)}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1250: {
            slidesPerView: 2.25,
            spaceBetween: 50,
          },
        }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {data.map((element, index) => {
          return (
            <SwiperSlide key={index} className="testimonial-carousel__slide">
              <SingleTestimonial testimonial={element.node}></SingleTestimonial>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

TestimonialCarousel.propTypes = {
  data: PropTypes.array,
  swipperCreated: PropTypes.func,
}

TestimonialCarousel.defaultProps = {
  data: [],
}

export default TestimonialCarousel
