import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown"

const WhyCarebitsBanner = ({ cms }) => {
  return (
    <>
      <Box
        className="why-carebits-banner"
        component="section"
        display="flex"
        flexDirection="column"
      >
        <Container>
          <div className="why-carebits-banner__right">
            <Markdown allowDangerousHtml>{cms.title}</Markdown>
            <Markdown allowDangerousHtml>{cms.content}</Markdown>
          </div>
        </Container>
      </Box>
    </>
  )
}

export default WhyCarebitsBanner
